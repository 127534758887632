<template>
	<div class="contBox" :class="themeClass">
		<img src="@/assets/MemberCenter/bag.png" style="width:100%;height:571px" />
		<div class="memberCont">
			<div style="background-color:#ffffff;margin: 28px 30px;">
				<div class="margin-rl-22">
					<!-- 权益等级 -->
					<div class='flex-row-center-center'  v-if="levelList.length >0">
						<div class="flex-row  margin-b-40" style="padding-top: 45px;">
							<img src="@/assets/MemberCenter/left.png" alt="" style="height:26px;line-height: 26px;"/>
							<div class="font-size30 font-weight700 font-color-333" style="padding:0 25px;line-height: 26px;">权益等级</div>
							<img src="@/assets/MemberCenter/right.png" style="height:26px;line-height: 26px;" />
						</div>
					</div>
					<div class="flex-row-center-center margin-b-40" v-if="levelList.length >0">
						<div class="growUpBtn font-size25 font-weight700 font-color-000  flex-row-center-center">
							我的成长值：{{fEndBalance}}</div>
					</div>
					<div class="flex-row-space-between ">
						<div class="growUpItem flex-row-center-center" v-for="(item,index) in levelList"
							:key="item.fGradeID" :class="index ==0?'growUpItem0':index== 1?'growUpItem1':index== 2?'growUpItem2':index== 3?'growUpItem3'
								 :index== 4?'growUpItem4':index== 5?'growUpItem5':''">
							<div>

								<div class='flex-row-center-center'>
									<i class="iconfont icon--viptubiao-copy-copy-copy1" style="font-size: 60px; text-align: center;" :class="index ==0?'iconColor0':index== 1?'iconColor1':index== 2?'iconColor2':index== 3?'iconColor3'
											:index== 4?'iconColor4':index== 5?'iconColor5':''"></i>
								</div>
								<div class="font-size18 font-weight700 text-center margin-t-20" :class="index ==0?'fontColor0':index== 1?'fontColor1':index== 2?'fontColor2':index== 3?'fontColor3'
										:index== 4?'fontColor4':index== 5?'fontColor5':''">{{item.fGradeName}}</div>
								<div class="font-size13 text-center margin-t-8" :class="index ==0?'fontColor0':index== 1?'fontColor1':index== 2?'fontColor2':index== 3?'fontColor3'
										:index== 4?'fontColor4':index== 5?'fontColor5':''">成长值{{item.fMinGrowupData}}点</div>
							</div>
						</div>
					</div>
					<!-- 权益等级 -->
					<!-- 成长值构成 -->
					<div class='flex-row-center-center' style="margin-top:48px;margin-bottom: 57px;" v-if="taskList[0].MemberGrowthList.length !=0">
						<div class="flex-row">
							<img src="@/assets/MemberCenter/left.png"style="height:26px;line-height: 26px;" />
							<div class="font-size30 font-weight700 font-color-333" style="padding:0 25px;line-height: 26px;">成长值构成</div>
							<img src="@/assets/MemberCenter/right.png"style="height:26px;line-height: 26px;" />
						</div>
					</div>
					<div class='flex-row-space-between' v-if="taskList[0].MemberGrowthList.length !=0">
						<div class="itemBox" v-for="(item,index) in taskList" :key="index">
							<div style="margin:27px 20px">
								<div class="flex-row-align-center margin-b-20">
									<div v-if="index ==0" class="penple flex-row-center-center">
										<i class="iconfont icon-fensizengchang" style="font-size: 40px;"></i>
									</div>
									<div v-if="index ==1" class="penple flex-row-center-center">
										<i class=" iconfont icon-ren-jianshao" style="font-size: 40px;"></i>
									</div>
									<div class="font-size25 font-weight700 font-color-333 margin-l-15">
										{{item.taskTitle}}
									</div>
								</div>
								<div class="font-size13 font-color-666 " style="line-height: 28px;"
									v-for="(growItem,growIndex) in item.MemberGrowthList">
									{{growIndex+1}}.{{growItem}}
								</div>
							</div>
						</div>
					</div>
					<!-- 成长值构成 -->
					<!-- 规则说明 -->
					<div class='flex-row-center-center' style="margin-top:48px;margin-bottom: 57px;" v-if="this.MembershipList.tabTitle.length >0">
						<div class="flex-row">
							<img src="@/assets/MemberCenter/left.png" style="height:26px;line-height: 26px;" />
							<div class="font-size30 font-weight700 font-color-333" style="padding:0 25px;line-height: 26px;">规则说明</div>
							<img src="@/assets/MemberCenter/right.png" style="height:26px;line-height: 26px;" />
						</div>
					</div>
					<table v-model="MembershipList" class="tableBox" border="0" cellspacing="0" cellpadding="0">
						<thead>
							<th v-for="(item,index) in this.MembershipList.tabTitle" :key="index+'_'" :width="item =='序号'?'80px':'150px'">
								{{item}}
							</th>
						</thead>
						<tbody>
							<tr v-for="(item,index) in this.MembershipList.tabBody" :key="index">
								<td v-for="(ite,ind) in item" :key="ind"
									:style="index % 2 != 0 ? 'background: #dfe3e5':''">{{ite}}</td>
							</tr>

						</tbody>
					</table>
					<!-- 规则说明 -->
					<!-- 成长值说明 -->
					<div style='padding-bottom: 28px;'>
						<div style="margin-top:33px;margin-bottom:25px;"
							class="font-size25 font-color-333 font-weight700">成长值说明</div>
						<div class="font-size13 font-color-333 " style="line-height: 28px"
							v-for="(item,index) of integraList" :key="index">{{index+1}}、{{item}}</div>
					</div>
					<!-- 成长值说明 -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue'
	export default {
		data() {
			return {
				fullscreenLoading: false,
				bgIndex:'',//卡片背景动态等级颜色
				borTopColor:'',//卡片弧度动态等级颜色
				membGrowInform:{},//左侧会员成长值信息
				fEndBalance:"",//我的成长值
				level: '1',
				memberLevel: '普通',
				memberGrowth: '500',
				current: 1,
				levelList: [
				],
				endMember:{},
				memberRight: [{
						'memberRightPic': 'icon-dangao',
						'memberrightName': '生日金币'
					},
					{
						'memberRightPic': 'icon-jinbi4',
						'memberrightName': '首次升级'
					},
					{
						'memberRightPic': 'icon-present-fill',
						'memberrightName': 'RMB500礼品'
					},
					{
						'memberRightPic': 'icon-kefu',
						'memberrightName': '专属客服'
					},
					{
						'memberRightPic': 'icon-guanggao',
						'memberrightName': '赠送广告位'
					}
				],
				memberLevel: '普通',
				noticeList: [
					'1关于会员的公告通知信息在此显示作为提醒~',
					'2关于会员的公告通知信息在此显示作为提醒~~',
					'3关于会员的公告通知信息在此显示作为提醒~~~'
				],
				noticeShow: 'true',
				//会员权益表格
				MembershipList:{
					tabTitle:[],
					tabBody:[]
				},
				taskList: [{
					taskTitle: '增加成长值',
					MemberGrowthList: [
						],
					},
					{
						taskTitle: '扣减成长值',
						MemberGrowthList: [
						],
					}],
				growList: [{
						id: 1,
						grayCont: "每日签到",
						grawValue: "送1点成长值",
						typeValue: "去签到"
					},
					{
						id: 2,
						grayCont: "领取任务并完成",
						grawValue: "送5点成长值,可叠加任务",
						typeValue: "去领取"
					},
					{
						id: 3,
						grayCont: "每日首次分享",
						grawValue: "送1点成长值",
						typeValue: "去分享"
					},
					{
						id: 4,
						grayCont: "购物满1000元,取整计算",
						grawValue: "送1点成长值",
						typeValue: "去购买"
					}
				],
				cutTime: "7个自然日",
				cutType: "分享、提货、充值、付款",
				cutNum: "减扣10点",
				cutDate: "每年12月1日至次年3月31",
				integraList: [
					"会员生日指客户在平台的注册日，会员生日当天可获赠相应生日金币。",
					"首次升至青铜及以上等级会员，平台将赠送相应数量金币。",
					"首次升至黄金及以上等级会员，将享受平台专属客服优质服务。",
					"每年1月1日，平台将根据会员上一年度实际销售收入进行核算，小于标准收入的会员将会予以降级。",
				],
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			fEndBalance1: function(){
				console.log('会员成长值'+this.endMember.fMinGrowupData)
				let endMember = this.endMember.fMinGrowupData;//最高级会员值
				let fEndBalance1 = 0;//当前角度  //this.fEndBalance 我的成长值 
				
				fEndBalance1 = Math.round((68*this.fEndBalance)/endMember);
				
				console.log('下一成长值',)
				return fEndBalance1;
			}
		},
		components: {
			breadcrumb
		
		},
		created(){
			setInterval(this.showNotice, 2000);
			this.getCustomerGrade();
			this.getMyGrowth();
			this.getGrowthBill();
			this.getGrowthList();
			this.getMembershipRights();//会员权益
		},
		mounted(){
			this.getCustomerMemberPoints()
		},
		methods: {
			getCustomerMemberPoints(){
				this.ApiRequestPost('/api/mall/ebbase/customer-grade/get-customer-member-points',{
					
				}).then(result=> {					
					let cutomerNumberList = JSON.parse(result.obj)
					this.taskList[0].MemberGrowthList[0] = `每日签到，送${cutomerNumberList[0].FSinGrowupData}点成长值；`
					this.taskList[0].MemberGrowthList[1] =
						`每日首次分享平台APP，送${cutomerNumberList[0].FShareGrowupData}点成长值；`
					this.taskList[0].MemberGrowthList[2] =
						`成功邀请1名会员，送${cutomerNumberList[0].FInviteGrowupData}点成长值；`
					this.taskList[0].MemberGrowthList[3] =
						`提货出厂每满${cutomerNumberList[0].FDeliverGoodsMoney}元（取整计算），赠送${cutomerNumberList[0].FDeliverGoodsGrowupData}点成长值（企业购提货除外）；`
					this.taskList[1].MemberGrowthList[0] =
						`无动态扣减：商城会员连续${cutomerNumberList[0].FNoDynamicDays}日无签到、分享、提货、充值、付款视为无动态，扣减${cutomerNumberList[0].FNoDynamicGrowupData}点成长值；`
					this.taskList[1].MemberGrowthList[1] = `会员保级：每年会员需达到当前等级最低交易额，未达到则会进行降级，最低交易金额见会员权益规则说明。`
					this.$forceUpdate()
					
				})
			},
			//查询会员中心客商等级
			getCustomerGrade(){
				this.fullscreenLoading = true;
				this.ApiRequestPost('api/mall/ebbase/customer-grade/get-current-customer-grade', {}).then(
					result => {
						this.fullscreenLoading = false;
						this.membGrowInform = result.obj;
						console.log(result.obj.fGradeID,"result.obj.fGradeID;");
						this.current = result.obj.fGradeID;
						//console.log(result,"查询会员中心客商等级");
						this.bgIndex = "bgColor"+this.current;
						this.borTopColor = 'borTopColor'+this.current;
					},
					rej => {}
				);
				
			},
			//我的成长值
			getMyGrowth(){
				this.ApiRequestPost('api/mall/ebbalance/customer-growup-data/get-my-crowup', {}).then(
					result => {
						if(result.obj!=null){
							this.fEndBalance = result.obj.fEndBalance;
						}
					},
					rej => {}
				);
				
			},
			//成长值账单
			getGrowthBill(){
				this.ApiRequestPost('api/mall/ebbalance/customer-growup-data-detail/get-growup-data-details', {}).then(
					result => {
					//console.log(result,"result成长值账单");
					},
					rej => {}
				);
				
			},
			//成长值列表
			getGrowthList(){
				this.ApiRequestPost('/api/mall/ebcustomer/rights/get-list-by-type-client', {}).then(
					result => {
						//console.log(result,"result成长值list");
						
						result.obj.splice(result.obj.findIndex(item => item.fGradeID == -1), 1);
						this.levelList = result.obj;
						console.log('我的成长值')
						console.log(this.fEndBalance);
						if(this.fEndBalance == ""){
							this.endMember = result.obj[result.obj.length-5];
						}
						if(this.fEndBalance >= 8000 && this.fEndBalance <=  19999  ){
							this.endMember = result.obj[result.obj.length-4];
						}
						if(this.fEndBalance >= 20000 && this.fEndBalance <=  59999  ){
							this.endMember = result.obj[result.obj.length-3];
						}
						if(this.fEndBalance >= 60000 && this.fEndBalance <=  119999  ){
							this.endMember = result.obj[result.obj.length-2];
						}
						if(this.fEndBalance >= 120000 && this.fEndBalance <=  239999  ){
							this.endMember = result.obj[result.obj.length-1];
						}
						// if(this.fEndBalance >= 120000 && this.fEndBalance <=  239999  ){
						// 	this.endMember = result.obj[result.obj.length-1];
						// }
						// this.endMember = result.obj[result.obj.length];
					},
					rej => {}
				);
				
			},
			//会员权益
			getMembershipRights(){
				this.ApiRequestPost('/api/mall/ebcustomer/rights/get-customer-grade-rights-client', {}).then(
					result => {
						result.obj = JSON.parse(result.obj);
						for(var key in result.obj[0]){
							this.MembershipList.tabTitle.push(key);
						}
						this.MembershipList.tabBody = result.obj;
						//console.log(result.obj,"result会员权益list");
					},
					rej => {}
				);
			},
			// rightChange(item) {
			// 	console.log(item,"item");
			// 	this.memberRight = this.levelList[item.id - 1];
			// 	this.current = item.id - 1
			// 	if (item.levName == "当前普通") {
			// 		this.memberLevel = "普通"
			// 	} else {
			// 		this.memberLevel = item.levName
			// 	}

			// },
			showNotice() {
				this.animate = true;
				setTimeout(() => {
					this.noticeList.push(this.noticeList[0]);
					this.noticeList.shift();
					this.animate = false;
				}, 500)
			},
			noticeColse() {
				this.noticeShow = false
			},
			//会员权益切换
			getTabMember(item) {
				this.memberLevel = item.levName;
			}

		}
	}
</script>

<style lang="scss" scoped>
	.contBox {
		width: 100%;
		background-color: rgba(223, 227, 229, 1);
		padding-bottom: 125px;
	}

	.memberCont {
		background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
		width: 1100px;
		margin: 0px auto;
		position: relative;
		border-radius: 30px;
		padding-bottom: 5px;

	}

	.growUpBtn {
		min-width: 387px;
		height: 55px;
		border-radius: 50px;
		background-color: #f0f0f0;
		border: 1px solid #cccccc;
	}

	.growUpItem {
		width: 156px;
		height: 199px;
		border-radius: 20px;
	}
	.growUpItem0 {
	
		border: 1px solid #A57B35;
	}
	.growUpItem1 {
		border: 1px solid #009966;
	}

	.growUpItem2 {
		border: 1px solid #728AB1;

	}

	.growUpItem3 {	border: 1px solid #C46835;

	}

	.growUpItem4 {
		
		border: 1px solid #4B72D2;

	}

	.growUpItem5 {
		
		border: 1px solid #5D4FB2;

	}

	.iconColor0 {
		background: linear-gradient(to right, #A57B35 0%, #FEF4E8 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.iconColor1 {
		background: linear-gradient(to right, #009966 0%, #E4F1E4 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.iconColor2 {
		background: linear-gradient(to right, #728AB1 0%, #D8E7F8 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.iconColor3 {
		background: linear-gradient(to right, #C46835 0%, #FEDBC5 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.iconColor4 {
		background: linear-gradient(to right, #5D4FB2 0%, #D5E8FF 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.iconColor5 {
		background: linear-gradient(to right, #5D4FB2 0%, #F3F2FF 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.fontColor0 {
		color: #A57B35;
	}

	.fontColor1 {
		color: #009966;
	}

	.fontColor2 {
		color: #728AB1;
	}

	.fontColor3 {
		color: #C46835;
	}

	.fontColor4 {
		color: #4B72D2;
	}

	.fontColor5 {
		color: #5D4FB2;
	}

	.itemBox {
		width: 479px;
		min-height: 245px;
		background: #eff1f0;
		border-radius: 20px;
	}

	.penple {
		width: 62px;
		height: 62px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
		background: linear-gradient(to right, #FFFFFF 0%, #E4E4E4 100%);
		border-radius: 50%;
	}

	.tableBox {
		font-size: 14px;
		border-left: 1px solid #d7d7d7;
		border-bottom: 1px solid #d7d7d7;
		color: #666;
	}

	.tableBox th {
		background: #dfe3e5;
		color: #333;
	}

	.tableBox td,
	.tableBox th {
		height: 46px;
		text-align: center;
		border: 1px solid #d7d7d7;
		border-left: none;
		border-bottom: none;

	}
</style>